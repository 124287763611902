import React, { useState, useEffect, useRef } from "react"
import Lottie from 'react-lottie'
import { Link } from "gatsby"
import { Fade } from "react-awesome-reveal";
import * as refreshAnimationData from '../images/refresh.json'
import HelixIcon from "../components/helixIcon";

const NewsPagination = ({ posts }) => {

  const refreshAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: refreshAnimationData.default
  };

  // State for the list
  const [list, setList] = useState([...posts.slice(0, 12)])
  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false)
  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(posts.length > 12)
  //Set a ref for the loading div
  const loadRef = useRef()
  // Handle intersection with load more div
  const handleObserver = (entities) => {
    const target = entities[0]
    if (target.isIntersecting) {
      setLoadMore(true)
    }
  }
  //Initialize the intersection observer API
  useEffect(() => {
    var options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    }
    const observer = new IntersectionObserver(handleObserver, options)
    if (loadRef.current) {
      observer.observe(loadRef.current)
    }
  }, [])
  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < posts.length
      const nextResults = isMore
        ? posts.slice(currentLength, currentLength + 12)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line
  //Check if there is more
  useEffect(() => {
    const isMore = list.length < posts.length
    setHasMore(isMore)
  }, [list]) //eslint-disable-line

  return (
    <div className="news-wrapper">
      <HelixIcon />
      <div className="container">
        {list.map(post => {
          return (
            <Fade triggerOnce cascade key={post.node.fields.slug}>
              <Link to={post.node.fields.slug}>
                <article className="article-tile">
                  <span>
                    {post.node.frontmatter.categories.map((category, i) => [
                      i > 0 && ", ", category
                    ])}
                  </span>
                  <h2>{post.node.frontmatter.title}</h2>
                  <p className="author">{post.node.frontmatter.author} · {post.node.frontmatter.date}</p>
                  <p>{post.node.frontmatter.caption}</p>
                </article>
              </Link>
            </Fade>
          );
        })}
        <div className="loading-element" ref={loadRef}>
          {hasMore ?
            <Lottie options={refreshAnimationOptions} width={200} height={150} />
          :
            <div className="loaded-badge">
              <p>All posts loaded!</p>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default NewsPagination
