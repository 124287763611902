import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"
import NewsHeader from "../components/newsHeader"
import NewsPagination from "../components/newsPagination"

const CategoryTemplate = ({ data }) => {

  const pageContent = data.pageContent.frontmatter;
  const categories = data.categories.edges;
  const posts = data.articles.edges

  return (
    <Layout classes="news">
      <Seo title="Lorem" description="Lorem" />
      <NewsHeader pageContent={pageContent} categories={categories} pageTitle={pageContent.title} />
      <NewsPagination posts={posts} />
    </Layout>
  );
}

export const pageQuery = graphql`query($title: String) {
  pageContent: markdownRemark(frontmatter: {title: {eq: $title}}) {
    frontmatter {
      title
      caption
    }
  }
  articles: allMarkdownRemark(
    sort: {fields: [frontmatter___data_date], order: DESC}
    filter: {frontmatter: {template_key: {eq: "article"}, categories: {eq: $title}}}
  ) {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          template_key
          categories
          date
          title
          author
          caption
        }
      }
    }
  }
  categories: allMarkdownRemark(
    filter: {frontmatter: {template_key: {eq: "category"}}}
  ) {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          title
          caption
        }
      }
    }
  }
}
`

export default CategoryTemplate
