import React from "react"
// import { Link } from 'gatsby'
import { Fade } from "react-awesome-reveal";

const NewsHeader = ({ pageContent, categories, pageTitle }) => {

  // let classes = 'button btn-bordered';
  // console.log(pageTitle);
  // if (pageTitle === 'News & Events') {
  //   classes = 'button';
  // }

  return (
    <section className="hero">
      <div className="container">
        <Fade triggerOnce>
          <h1>{pageContent.title}</h1>
          <p>{pageContent.caption}</p>
          {/* <div className="categories">
            <Link to="/news-&-events/" className={classes}>All</Link>
            {categories.map(category => {
              classes = 'button btn-bordered';
              if (pageContent.title === category.node.frontmatter.title) {
                classes = 'button';
              }
              return (
                <Link key={category.node.fields.slug} to={category.node.fields.slug} className={classes}>{category.node.frontmatter.title}</Link>
              )
            })}
          </div> */}
        </Fade>
      </div>
    </section>
  )
}

export default NewsHeader
